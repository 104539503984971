import React, { createContext, useEffect, useState } from 'react';

import {
  isBaymaxEnabledVar,
  RoutePath,
  selectedAdAccountIdVar,
  selectedOrganizationIdVar,
  userAmsUserIdVar,
} from '../../constants';
import { localStorageGhostModeName } from '../../constants/ghostMode';
import { User } from '../../utils/user';
import { defaultGhostModeValues, OpenGhostModeProps } from './constants';

export const GhostModeContext = createContext(defaultGhostModeValues);

export type GhostModeContainerProps = {
  children: React.ReactNode;
};

export const GhostModeContainer = ({ children }: GhostModeContainerProps): JSX.Element => {
  const [isGhostMode, setIsGhostMode] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const ghostModeData = localStorage.getItem(localStorageGhostModeName);

    if (ghostModeData) {
      const { organizationName, organizationId, adAccountId, userId } = JSON.parse(ghostModeData);

      setOrganizationName(organizationName);
      selectedOrganizationIdVar(organizationId);
      selectedAdAccountIdVar(adAccountId);
      userAmsUserIdVar(userId);
      setIsGhostMode(true);
    }
  }, []);

  const handleOpenGhostMode = ({
    adAccountId,
    organizationId,
    organizationName,
    redirectUrl,
  }: OpenGhostModeProps): void => {
    const { userInfo } = User.getUserInfo();

    const ghostModeData = JSON.stringify({
      organizationId,
      organizationName,
      adAccountId,
      openedFrom: window.location.pathname,
      userId: userInfo?.ams_user_id,
    });

    setIsGhostMode(false);
    selectedOrganizationIdVar(organizationId);
    selectedAdAccountIdVar(adAccountId);
    isBaymaxEnabledVar(false);

    localStorage.setItem(localStorageGhostModeName, ghostModeData);
    window.open(`${window.location.origin}${redirectUrl || `${RoutePath.ACCOUNT}/profile`}`, '_self');
  };

  const handleCloseGhostMode = async (): Promise<void> => {
    const ghostModeData = localStorage.getItem(localStorageGhostModeName);

    if (ghostModeData) {
      const { openedFrom } = JSON.parse(ghostModeData);
      window.location.replace(openedFrom);

      userAmsUserIdVar(undefined);
      selectedAdAccountIdVar(undefined);
      selectedOrganizationIdVar(undefined);
      isBaymaxEnabledVar(false);

      localStorage.removeItem(localStorageGhostModeName);
    }
  };

  return (
    <GhostModeContext.Provider value={{ isGhostMode, organizationName, handleOpenGhostMode, handleCloseGhostMode }}>
      {children}
    </GhostModeContext.Provider>
  );
};

export default GhostModeContainer;
