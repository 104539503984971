import { colors } from '@hulu/design-tokens';

import { ThemeBranch } from '../types';

const gray = {
  light: {
    fg: ['#0B0C0F', '#2C313A', '#586174', '#6E7A91', '#A8AFBD', '#C5CAD3', '#F7F9FA'],
    bg0: ['#FFFFFF', '#F6F7F8', '#F0F2F4', '#E2E4E9', '#D3D7DE', '#C5CAD3', '#8B94A7'],
    bg1: ['#F0F2F4', '#E8EAED', '#E2E4E9', '#D3D7DE', '#C5CAD3', '#B6BCC8', '#7C879C'],
    bg2: ['#E2E4E9', '#D9DCE2', '#D3D7DE', '#C5CAD3', '#B6BCC8', '#A8AFBD', '#6E7A91', '#F0F1F5'],
    bg3: ['#E2E9EC', '#B8C7D0', '#D4DEE3', '#526374', '#EFF3F5', '#E2E9EC', '#B8C7D0'],
    fg1: ['#8498A9', '#6A8095', '#9EB0BD', '#B6C4D3', '#E6E6FF', '#F7F7F9', '#13203966'],
  },
  dark: {
    fg: ['#F0F2F4', '#C5CAD3', '#8B94A7', '#6E7A91', '#424957', '#2C313A', '#B9BFCB', '#171C20'],
    bg0: ['#16181D', '#1D2026', '#21242C', '#2C313A', '#373D49', '#424957', '#6E7A91'],
    bg1: ['#21242C', '#272C34', '#2C313A', '#373D49', '#424957', '#4D5566', '#7C879C'],
    bg2: ['#2C313A', '#323843', '#373D49', '#424957', '#4D5566', '#586174', '#8B94A7'],
    bg3: ['#171C20', '#3A4652', '#B6C4D3'],
    fg1: ['#3E4D5B', '#EFF3F5', '#040405', '#7596D3'],
  },
} as const;

const blue = {
  solid: '#1972E6',
  light: {
    fg: ['#0A2E5C', '#145BB8', '#D9E4FA', '#B3CAF4', '#EDF5FF', '#5FA5FF'],
    bg: ['#D1E3FA', '#A3C7F5', '#75AAF0', '#478EEB', '#1972E6', '#145BB8', '#A5C8F5'],
    bg1: ['#E8F1FC', '#3611C8', '#ECF2FC', '#5990F6', '#69E9FF', '#176EE1', '#D6E8FF'],
  },
  dark: {
    fg: ['#A3C7F5', '#478EEB', '#2358BA', '#8EC0FF', '#BAD0FA'],
    bg: ['#0A2E5C', '#0F448A', '#145BB8', '#1972E6', '#478EEB', '#75AAF0'],
    bg1: ['#1950B5', '#ECF2FC', '#C6D7F7', '#8DAFEF', '#46DEFD', '#928AFF', '#281379', '#dde9ff'],
    bg2: ['#143F8F', '#0D2A5F', '#3772DF', '#1EA1C8'],
  },
} as const;

const red = {
  solid: '#E6194D',
  light: {
    fg: ['#5D0A1F', '#B8143D'],
    bg: ['#FAD1DB', '#F5A3B8', '#F07594', '#EB4770', '#E6194D', '#B8143D', '#FCE6EB'],
    bg1: ['#FFEBEF', '#FFBCC8', '#A00032'],
  },
  dark: {
    fg: ['#F5A3B8', '#EB4771', '#C20031'],
    bg: ['#5C0A1F', '#8A0F2F', '#B8143D', '#E6194D', '#EB4770', '#F07594'],
  },
} as const;

const yellow = {
  solid: '#FFAA00',
  light: {
    bg: ['#FCF5E8', '#F5DAA3'],
    fg: ['#5C410A'],
  },
  dark: {
    bg0: ['#664400', '#996600', '#CC8800', '#FFAA00', '#FFBB33', '#FFCC66'],
    bg1: ['#A64F00', '#FFE9D9', '#FFD2AD'],
  },
} as const;

const green = {
  solid: '#19E67F',
  light: {
    fg: ['#0A5C33', '#00C274'],
    bg0: ['#A3F5CC', '#EAFDF3'],
  },
  dark: {
    bg0: ['#0A5C33', '#0F8A4C', '#14B866', '#19E67F', '#47EB99', '#75F0B2'],
  },
} as const;

const brown = {
  solid: '#DB6600',
};

const indigo = {
  solid: '#CFCFFF',
};

const admanColors = {
  gray,
  blue,
  red,
  yellow,
  green,
  brown,
  indigo,
};

export type MCColorsTheme = ThemeBranch<{
  colors: typeof colors;
  admanColors: typeof admanColors;
}>;

export const DefaultTheme: MCColorsTheme = {
  colors,
  admanColors,
};
