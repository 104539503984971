import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const MultipleDropDownWrapper = styled.div`
  position: relative;
  > p {
    margin: ${({ theme }): string => theme.spacers!.spacer02} 0;
    font-size: 12px;
    font-weight: 500 !important;
    color: #586174;
  }
`;

export const StyledInputWrapper = styled.div<{ $focused: boolean; $errMsg?: boolean }>`
  width: 100%;
  position: relative;

  input {
    padding: ${({ theme }): string => `${theme.spacers!.spacer02} ${theme.spacers!.spacer03}`};
    width: 100%;
    height: 40px;
    text-overflow: ellipsis;
    border: 1px solid
      ${({ $focused, theme, $errMsg }): string =>
        $errMsg
          ? theme.admanColors!.red.solid
          : $focused
          ? theme.admanColors!.gray.light.fg[0]
          : theme.admanColors!.gray.light.bg0[3]};
    border-radius: 4px;
    padding-right: ${({ theme }): string => `${theme.spacers!.spacer09}`};
    box-shadow: ${({ $focused, theme }): string =>
      $focused ? `inset 0 0 0 1px ${theme.admanColors!.gray.light.fg[0]}` : ''};

    &:focus-visible {
      border-color: ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};
      outline: none;
      box-shadow: inset 0 0 0 1px ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};
    }

    &:disabled {
      background-color: ${({ theme }): string => theme.admanColors!.gray.light.bg2[7]};
      color: ${({ theme }): string => theme.admanColors!.gray.dark.fg[6]};
      border: 1px solid ${({ theme }): string => theme.admanColors!.gray.dark.fg[6]};
    }

    &:hover {
      border: 2px solid ${({ theme }): string => theme.admanColors!.gray.dark.fg[6]};
    }
  }

  svg {
    position: absolute;
    top: 16px;
    right: 12px;
    transform: rotate(${({ $focused }): string => ($focused ? '180' : '0')}deg);
    transition: 0.2s;
  }

  div.transparent {
    position: absolute;
    top: 6px;
    right: 35px;
    width: 24px;
  }
`;

export const StyledErrorMessage = styled.div`
  color: ${({ theme }): string => theme.admanColors!.red.solid};
  font-size: 12px;
  line-height: 18px;
  padding: ${({ theme }): string => theme.spacers!.spacer01};
`;

export const StyledOptionWrapper = styled.div<{ $openOnTop?: boolean }>`
  margin-top: ${({ theme }): string => theme.spacers!.spacer01};
  overflow: hidden;
  width: 100%;
  background-color: ${({ theme }): string => theme.admanColors!.gray.light.bg0[0]};
  position: absolute;
  padding: ${({ theme }): string => theme.spacers!.spacer01};
  display: grid;
  border: 1px solid ${({ theme }): string => theme.admanColors!.gray.light.bg0[3]};
  box-shadow: 0 4px 16px -8px rgba(19, 32, 57, 0.4);
  border-radius: 12px;
  z-index: 10;
  bottom: ${({ $openOnTop }): string => ($openOnTop ? '45px' : '')};

  &:hover {
    color: ${({ theme }): string => theme.admanColors!.blue.solid};
  }

  > div {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};
    }
  }
`;

export const StyledWrapperOption = styled.div<{ $checked?: boolean }>`
  display: flex;
  border-radius: 8px;
  user-select: none;
  background-color: ${({ theme, $checked }): string => ($checked ? theme.admanColors!.blue.light.bg[0] : 'inherit')};
  color: ${({ theme, $checked }): string =>
    $checked ? theme.admanColors!.gray.light.fg[0] : theme.admanColors!.gray.light.fg[1]};

  &:hover {
    background-color: ${({ theme, $checked }): string =>
      $checked ? theme.admanColors!.blue.light.bg[1] : theme.admanColors!.gray.light.bg0[1]};
  }
`;

export const StyledOption = styled.label<{ withoutSelectIcon?: boolean; $checkedValues?: boolean[] }>`
  height: ${({ withoutSelectIcon }): string => (withoutSelectIcon ? 'inherit' : '50px')};
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.admanColors!.gray.light.fg[0]};
  background-color: ${({ theme, $checkedValues = [] }): string => {
    const [, currentValue] = $checkedValues;

    return currentValue ? theme.admanColors!.blue.light.bg[0] : 'inherit';
  }};
  border-radius: ${({ withoutSelectIcon, $checkedValues = [] }): string => {
    const [prevValue, currentValue, nextValue] = $checkedValues;
    const top = prevValue && currentValue ? '0 0' : '8px 8px';
    const bottom = nextValue && currentValue ? '0 0' : '8px 8px';

    return withoutSelectIcon ? `${top} ${bottom}` : 'inherit';
  }};
  &:hover {
    background-color: ${({ theme, $checkedValues = [] }): string => {
      const [, currentValue] = $checkedValues;

      return currentValue ? theme.admanColors!.blue.light.bg[1] : theme.admanColors!.gray.light.bg0[1];
    }};
  }

  svg {
    margin-left: ${({ theme }): string => theme.spacers!.spacer03};
    cursor: pointer;
  }
`;

export const StyledCheckboxInputWrapper = styled.div<{ $isSelected: boolean }>`
  margin-left: ${({ $isSelected, theme }): string => ($isSelected ? theme.spacers!.spacer03 : '34px')};
`;

export const StyledClearButton = styled(CloseCircleOutlined)`
  display: flex;

  svg {
    top: 12px;
    right: 39px;
    transform: none;
  }
`;
