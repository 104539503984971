import envConfig from '@hulu/env-config';
import type { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import qs from 'qs';

import { Cognito, LocalStorage } from './interfaces';
import type {
  GeneratedPermissions,
  IdToken,
  OauthCallbackOptions,
  RequiredPermissions,
  RequiredRole,
  RoleV4,
  UserInfo,
} from './types';
import { getCognitoURL, getUserRoleInfo, hasRequiredPermission } from './util';

interface DecodedUserTypeIdToken {
  user_type?: string;
}

export class User {
  private static generatedPermissions: GeneratedPermissions<string> | null = null;

  /**
   * Forces the user to log in.
   */
  static login(): void {
    window.location.href = getCognitoURL();
  }

  /**
   * Sends an OAuth Callback request
   * @param options.redirectToLoginOnError Forces the user to reattempt login if an error is encountered
   */
  static async oauthCallback(options: OauthCallbackOptions = {}, useXsrfToken: boolean = true): Promise<void> {
    const code = qs.parse(window.location.search)['?code'];

    if (!code && options.redirectToLoginOnError) return this.logout();

    try {
      const { status, data } = await Cognito.sendOauthCallback(code as string, useXsrfToken);

      if (status !== 200 || !data) throw new Error(`Exchange code: ${code} for token failed, error: ${data.error}`);
      LocalStorage.setTokens(data);

      // If user_type is "HUB", redirect to the HUB URL
      if (data.id_token && User.shouldRedirectToHub(data.id_token)) {
        window.location.href = envConfig.REACT_APP_REDIRECT_TO_HUB_URL;
        return;
      }

      // Otherwise, redirect to successfulRedirectHref or home page
      window.location.href = options.successfulRedirectHref ?? '/';
    } catch (error) {
      if (options.redirectToLoginOnError) return this.logout();

      const err = error as AxiosError<Record<'error', unknown>>;
      throw new Error(`Exchange code: ${code} for token failed, error: ${err?.response?.data?.error}`);
    }
  }

  /**
   * Clears any existing tokens and forces the user to login again.
   */
  static logout(): void {
    console.log('Logging out user.');
    LocalStorage.clearTokens();
    this.login();
  }

  /**
   * @returns User token IDs and content
   */
  static getUserInfo(): UserInfo {
    const tokens = LocalStorage.getTokens();

    return {
      ...tokens,
      userInfo: tokens.idToken ? jwtDecode(tokens.idToken) : null,
    };
  }

  /**
   * This function examines the user's auth tokens and compares their roles to
   * the provided list of required permissions (along with the provided role
   * definitions). It then generates an object containing boolean flags for each
   * of the requiredPermissions values. The boolean flags indicate whether the user
   * is allowed to perform the associated action.
   *
   * The permissions object is returned directly by this function, and can also be
   * retrieved at a later time by calling `User.getPermissions()`
   * @param isAuthEnabled If false, all permissions are granted by default
   * @param requiredPermissions Object containing actions and the permissions required to perform those actions
   * @param roleDefinitions List of RoleV4 definitions
   * @returns The user's generated permissions, or null if no permissions are available.
   */
  static generateUserPermissions = <PermissionName extends string>(
    isAuthEnabled: boolean,
    requiredPermissions: RequiredPermissions<PermissionName>,
    roleDefinitions: RoleV4[]
  ): GeneratedPermissions<PermissionName> | null => {
    const roleInfo = getUserRoleInfo(User.getUserInfo(), roleDefinitions);

    User.generatedPermissions = Object.entries<RequiredRole>(requiredPermissions).reduce(
      (generated, required) => ({
        ...generated,
        [required[0]]: isAuthEnabled ? hasRequiredPermission(roleInfo, required[1]) : true,
      }),
      {} as GeneratedPermissions<PermissionName>
    );

    return User.generatedPermissions;
  };

  /**
   * This function should only be called *after* permissions have been generated
   * with the `User.generateUserPermissions` function.
   * @returns The user's generated permissions, or null if no permissions are available.
   */
  static getPermissions = <PermissionName extends string>(): GeneratedPermissions<PermissionName> | null => {
    return User.generatedPermissions;
  };

  /**
   * Checks the given idToken value to determine whether it has expired.
   * @param idToken JWT Token
   * @returns Boolean result of validation check
   */
  static isIdTokenValid = (idToken?: string | null): boolean => {
    if (!idToken) return false;

    const systemTime = Math.round(Date.now() / 1000);
    const { exp } = jwtDecode<IdToken>(idToken);

    return exp !== undefined && systemTime < exp;
  };

  /**
   * Attempts to refresh the user's ID tokens. Forces the user to login again if their token is invalid.
   */
  static checkIdToken = async (useXsrfToken?: boolean): Promise<void> => {
    const { refreshToken, idToken } = User.getUserInfo();

    if (User.isIdTokenValid(idToken)) return;

    try {
      if (!refreshToken) throw new Error();

      const { data } = await Cognito.sendTokenRefresh(refreshToken, useXsrfToken);

      LocalStorage.setTokens(data);
    } catch (error) {
      // If token refresh fails, we'll clear the user's tokens so they can try a fresh login
      LocalStorage.clearTokens();
    }
  };

  static shouldRedirectToHub(idToken: string): boolean {
    if (!idToken) {
      return false;
    }

    try {
      const decoded = jwtDecode<DecodedUserTypeIdToken>(idToken);
      return decoded.user_type === 'HUB';
    } catch (err) {
      console.error('Error decoding id_token in shouldRedirectToHub:', err);
      return false;
    }
  }
}
