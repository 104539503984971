// Please keep the list alphabetical
export enum RoutePath {
  ACCOUNT = '/account',
  ADMIN = '/admin',
  AD_GALLERY = '/ad-gallery',
  CAMPAIGNS = '/campaigns',
  CAMPAIGN_CREATE = '/campaigns/create',
  FORBIDDEN = '/forbidden',
  FORGOT_PASSWORD = '/forgot-password',
  HOME = '/',
  LOGIN = '/login',
  LINE_ITEMS = '/lineItems',
  PERFORMANCE = '/performance',
  LOGOUT = '/logout',
  NOT_ASSIGNED = '/not-assigned',
  NOT_FOUND = '/not-found',
  ONBOARDING = '/onboarding',
  ORG_SWITCHER = '/org-switcher',
  REPORTS = '/reports',
  RESEND_INVITE = '/resend-invite',
  SET_PASSWORD = '/set-password',
  SUMMARY = '/summary',
  NOTIFICATIONS = '/notifications',
}

export enum AdminRoutePath {
  AD_ACCOUNTS = '/ad-accounts',
  BROADCASTS = '/broadcasts',
  BROADCASTS_CREATE = '/broadcasts/create',
  BROADCASTS_DETAILS = '/broadcasts/details',
  BROADCASTS_UPDATE = '/broadcasts/update',
  CAMPAIGNS = '/campaigns',
  ORGANIZATIONS = '/organizations',
  REPORTS = '/reports',
  SELLERS = '/sellers',
  USERS = '/users',
  ADS = '/ads',
  LOGIN = '/login',
  OAUTH_CALLBACK = '/oauth/callback',
}

export enum BaymaxRoutePath {
  ACCOUNT = '/account',
  REPORT = '/:adAccountId/report-template/:reportTemplateId/report/*',
  HOME = '/:adAccountId',
  LOGIN = '/login',
  LOGOUT = '/logout',
}

export const HISTORY_BACK = -1;
