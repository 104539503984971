import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { Route, Routes } from '@hulu/react-router-dom';
import { lazy, Suspense } from 'react';

import { StyledLoader } from './components/styled';
import { TOOLTIP_CONTAINER_ID } from './components/TooltipContainer/constants';
import { TooltipContainer } from './components/TooltipContainer/styled';
import { RoutePath } from './constants';
import GhostModeContainer from './context/GhostMode';
import lazyRetry from './utils/lazyFetch';

const AdminApp = lazy(() => lazyRetry(() => import('./AdminApp'), 'AdminApp'));
const MarketerApp = lazy(() => lazyRetry(() => import('./MarketerApp'), 'MarketerApp'));

export function App(): JSX.Element {
  return (
    <GhostModeContainer>
      <TooltipContainer id={TOOLTIP_CONTAINER_ID} />
      <Suspense fallback={<StyledLoader />}>
        <Routes>
          <Route path={`${RoutePath.ADMIN}/*`} element={<AdminApp />} />
          <Route path="/*" element={<MarketerApp />} />
        </Routes>
      </Suspense>
    </GhostModeContainer>
  );
}

export default App;
