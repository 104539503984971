import envConfig from '@hulu/env-config';
import { Auth } from 'aws-amplify';
import { CookieOptions } from 'express';
import Cookie from 'universal-cookie';

export class HelpSiteCookieHelper {
  static helpSiteIdTokenCookieName: string = 'apex__dcmIdToken';
  static helpSiteRefreshTokenCookieName: string = 'apex__hamToken';
  static deviceKeyCookieName: string = 'apex__deviceKey';
  static lastAuthUserCookieName: string = 'apex__lastAuthUser';
  static lastClientIdCookieName: string = 'apex__lastClientId';
  static lastPoolIdCookieName: string = 'apex__lastPoolId'; // New cookie

  // Two separate CLIENT IDs, but a single user pool ID
  private static adminClientId = envConfig.REACT_APP_COGNITO_ADMIN_CLIENT_ID;
  private static marketerClientId = envConfig.REACT_APP_COGNITO_MARKETER_CLIENT_ID;

  // The user pool ID from environment (e.g. 'us-west-2_HgqlHRM0v')
  private static userPoolId = envConfig.REACT_APP_COGNITO_USER_POOL_ID;

  /**
   * Main function: Sets apex__hamToken, apex__dcmIdToken, apex__deviceKey, apex__lastAuthUser,
   *                apex__lastClientId, and apex__lastPoolId
   */
  static setHelpSiteCookieWithIdAndRefresh = async (
    refreshToken: string | undefined,
    idToken: string | undefined
  ): Promise<void> => {
    try {
      const cookies = new Cookie();
      const options = HelpSiteCookieHelper.getCookieOptions();

      // Set the refresh token cookie if provided
      if (refreshToken) {
        console.log(
          '[HelpSiteCookieHelper] Setting refresh token cookie:',
          HelpSiteCookieHelper.helpSiteRefreshTokenCookieName
        );
        cookies.set(HelpSiteCookieHelper.helpSiteRefreshTokenCookieName, refreshToken, options);
      } else {
        console.warn('[HelpSiteCookieHelper] No refreshToken provided.');
      }

      // Set the ID token cookie if provided
      if (idToken) {
        cookies.set(HelpSiteCookieHelper.helpSiteIdTokenCookieName, idToken, options);
      } else {
        console.warn('[HelpSiteCookieHelper] No idToken provided.');
      }

      // Try admin client ID first
      const wasAdminFound = HelpSiteCookieHelper.trySetLastAuthUserAndDeviceKey(
        HelpSiteCookieHelper.adminClientId,
        cookies,
        options
      );

      // Also check marketer client ID
      const wasMarketerFound = HelpSiteCookieHelper.trySetLastAuthUserAndDeviceKey(
        HelpSiteCookieHelper.marketerClientId,
        cookies,
        options
      );

      if (!wasAdminFound && !wasMarketerFound) {
        console.warn('[HelpSiteCookieHelper] Neither admin nor marketer LastAuthUser was found in localStorage.');
      }
    } catch (e) {
      console.error('[HelpSiteCookieHelper] Unable to set help site cookie.', e);
    }
  };

  /**
   * Helper function to try retrieving lastAuthUser and deviceKey from localStorage
   * for a given clientId. If found, sets the cookies (apex__lastAuthUser, apex__deviceKey,
   * apex__lastClientId, apex__lastPoolId) and returns true; otherwise returns false.
   */
  private static trySetLastAuthUserAndDeviceKey(
    clientId: string | undefined,
    cookies: Cookie,
    options: CookieOptions
  ): boolean {
    if (!clientId) {
      console.warn('[HelpSiteCookieHelper] No clientId provided, skipping...');
      return false;
    }

    // e.g. 'CognitoIdentityServiceProvider.619b97c2i89dlru2ar3ah2t478.LastAuthUser'
    const lastAuthUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    const lastAuthUser = localStorage.getItem(lastAuthUserKey);

    if (!lastAuthUser) {
      console.log(`[HelpSiteCookieHelper] No lastAuthUser found in localStorage for clientId: ${clientId}`);
      return false;
    }

    // Set lastAuthUser cookie
    cookies.set(HelpSiteCookieHelper.lastAuthUserCookieName, lastAuthUser, options);

    // Set apex__lastClientId
    cookies.set(HelpSiteCookieHelper.lastClientIdCookieName, clientId, options);

    // Store the userPoolId from our env config
    cookies.set(HelpSiteCookieHelper.lastPoolIdCookieName, HelpSiteCookieHelper.userPoolId, options);

    // Attempt to retrieve deviceKey for that user
    const deviceKeyKey = `CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.deviceKey`;
    const deviceKey = localStorage.getItem(deviceKeyKey);

    if (!deviceKey) {
      console.log(
        `[HelpSiteCookieHelper] No deviceKey found in localStorage for clientId: ${clientId}, user: ${lastAuthUser}`
      );
      return true; // We did find lastAuthUser, so partially successful
    }

    // Set deviceKey cookie
    console.log('[HelpSiteCookieHelper] Setting deviceKey cookie:', HelpSiteCookieHelper.deviceKeyCookieName);
    cookies.set(HelpSiteCookieHelper.deviceKeyCookieName, deviceKey, options);

    return true;
  }

  /**
   * Standard wrapper that obtains tokens from currentSession()
   * then calls setHelpSiteCookieWithIdAndRefresh to set them.
   */
  static setHelpSiteCookieForNonAdmin = async (): Promise<void> => {
    try {
      const session = await Auth.currentSession();

      const refreshToken = session.getRefreshToken().getToken();
      const idToken = session.getIdToken().getJwtToken();

      await this.setHelpSiteCookieWithIdAndRefresh(refreshToken, idToken);
    } catch (e) {
      console.log('[HelpSiteCookieHelper] Unable to set non-admin help site cookie.', e);
    }
  };

  /**
   * Removes all help site cookies: refresh token, ID token, device key, lastAuthUser, lastClientId, lastPoolId
   */
  static unsetHelpSiteCookie = (): void => {
    const cookies = new Cookie();
    const options = HelpSiteCookieHelper.getCookieOptions();

    cookies.remove(HelpSiteCookieHelper.helpSiteIdTokenCookieName, options);
    cookies.remove(HelpSiteCookieHelper.helpSiteRefreshTokenCookieName, options);
    cookies.remove(HelpSiteCookieHelper.lastAuthUserCookieName, options);
    cookies.remove(HelpSiteCookieHelper.deviceKeyCookieName, options);
    cookies.remove(HelpSiteCookieHelper.lastClientIdCookieName, options);
    cookies.remove(HelpSiteCookieHelper.lastPoolIdCookieName, options);
  };

  /**
   * Determines the appropriate cookie domain based on hostname
   */
  private static getCookieOptions = (): CookieOptions => {
    const host =
      typeof window !== 'undefined' && window.location && window.location.hostname ? window.location.hostname : '';

    let domain = host;

    if (host.includes('dcm.disneyadvertising.com')) {
      domain = '.dcm.disneyadvertising.com';
    } else if (host.includes('staging.hulu.com')) {
      domain = '.staging.hulu.com';
    } else if (host === 'localhost') {
      domain = 'localhost';
    } else {
      domain = host || 'localhost';
    }

    return {
      domain,
      secure: true,
      path: '/',
    };
  };
}
